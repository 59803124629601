<template>
  <div class="pb-3">
    <LoadingOverlay v-if="isFetching" />
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        {{ jobTitle }}
        <CIcon
          v-if="isEditable"
          name="cil-info"
          class="hinticon-multi-select mr-0"
          v-c-tooltip="
            'To edit  job details please go to the Job Board and select Edit on the required Job'
          "
        />
      </h6>

      <div class="d-flex justify-content-end action-wrapper">
        <a class="px-2 btn" v-if="isEditable" @click="editConsultantDetails">
          <em class="fas fa-pencil-alt" alt="Edit" />
        </a>
      </div>
    </div>

    <CRow>
      <CCol>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Type</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ type }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="!isAHP">
          <CCol lg="4">
            <label class="text-gray">Requirement</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ levelLabels }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">{{
              isAHP ? "Requirement" : (isNonClinicalAdmin ? "Main Area of Expertise":"Speciality")
            }}</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ speciality }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="sub_speciality && !isNurse && !isNonClinicalAdmin">
          <CCol lg="4">
            <label class="text-gray">{{
              isAHP ? "Sub Type / License" : "Sub Speciality"
            }}</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ sub_speciality || "--" }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="isNurse || isNonClinicalAdmin">
          <CCol lg="4">
            <label class="text-gray">Other Areas of Expertise</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{
              other_areas_of_expertise_ids || "--"
            }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="isNurse || isAHP">
          <CCol lg="4">
            <label class="text-gray">Number of years in Speciality</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ experience_in_years }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="!isAHP && !isNonClinicalAdmin">
          <CCol lg="4">
            <label class="text-gray">Special Interests</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ special_interests }}</span>
          </CCol>
        </CRow>
        <CRow
          class="profileView"
          v-if="isNurse && profile.location.country_id == 182"
        >
          <CCol lg="4">
            <label class="text-gray"> Current NHS Band</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ current_nhs_bands }}</span>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <ConsultantDetailsEdit
      :jobInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="basicInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Role, getScope, CONSTANT as CONST } from "@/helpers/helper";
import ConsultantDetailsEdit from "./EditForm/ConsultantDetailsEdit";
export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConsultantDetailsEdit,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      isFetching: false,
    };
  },
  computed: {
    ...mapGetters(["isCandidateSupplierFromAccessToken"]),
    jobTitle() {
      return this.profile?.job_title || "--";
    },
    levelLabels() {
      return this.profile.level_names && this.profile.level_names.length
        ? this.profile.level_names.join(", ")
        : "--";
    },
    type() {
      return this.profile?.candidate_type?.candidate_type || "--";
    },
    current_nhs_bands() {
      return this.profile?.current_nhs_bands?.length
        ? this.profile?.current_nhs_bands.map((val) => val.band_name).join(", ")
        : "All";
    },
    speciality() {
      let speciality = this.profile?.specialities?.length
        ? this.profile?.specialities?.filter(
            (val) => val.is_current == true || val.is_current == null
          )[0]
        : [];
      return this.profile?.candidate_type_id == CONST.NURSE
        ? `${speciality.speciality?.speciality}${
            speciality.sub_speciality?.sub_speciality
              ? ` - ${speciality.sub_speciality?.sub_speciality}`
              : ""
          }` || "--"
        : speciality.speciality?.speciality || "--";
    },
    sub_speciality() {
      return this.profile?.specialities?.length
        ? this.profile?.specialities[0]?.sub_speciality?.sub_speciality
        : null;
    },
    special_interests() {
      return (
        this.profile?.specialities
          ?.filter((val) => val.is_current || val.is_current === null)[0]
          ?.job_special_interest.map((v) => v.special_interest)
          ?.join(", ") || "--"
      );
    },
    location() {
      return this.profile?.location ? this.profile?.location?.location : "--";
    },
    experience_in_years() {
      return (
        this.profile?.specialities?.filter(
          (val) => val.is_current || val.is_current === null
        )[0]?.experience_in_years || "--"
      );
    },
    other_areas_of_expertise_ids() {
      return this.profile?.specialities?.length
        ? this.profile?.specialities
            ?.filter((val) => val.is_current === false)
            .map(
              (val) =>
                `${val.speciality?.speciality}${
                  val.sub_speciality?.sub_speciality
                    ? ` - ${val.sub_speciality?.sub_speciality}`
                    : ""
                }`
            )
            .join(", ")
        : "--";
    },
    isNurse() {
      return this.profile?.candidate_type?.candidate_type_id == 2
        ? true
        : false;
    },
    isAHP() {
      return this.profile?.candidate_type?.candidate_type_id == 3
        ? true
        : false;
    },
    isNonClinicalAdmin() {
      return this.profile?.candidate_type?.candidate_type_id == 6
        ? true
        : false;
    },
  },
  methods: {
    ...mapActions([
      "updateJobByDetailPage",
      "getJobById",
      "initFetchConsultantInfoOptions",
      "updateJobSpeciality",
      "fetchSpecialInterestByParams",
    ]),
    editConsultantDetails() {
      this.isFetching = true;
      let speciality = this.profile?.specialities?.filter(
        (val) => val.is_current == true || val.is_current == null
      )[0];
      let payload = {
        speciality_id: speciality?.speciality_id,
        candidate_type_id: this.profile?.candidate_type_id,
      }
      let appendAction = [
        this.initFetchConsultantInfoOptions(payload)
      ]
      Promise.all([appendAction]).then((res) => {
        this.isFetching = false;
        this.EditModal.isShowPopup = true;
      });
    },
    basicInfoModalCallBack(action, data) {
      if (!action) this.EditModal.isShowPopup = false;
      if (action && data != null) {
        let { speciality, organisation_id, ...rest } = data;
        let { job_id, customer_uid } = this.profile;
        let appendAction = [];
        appendAction.push(
          this.updateJobByDetailPage({
            organisation_id,
            customer_uid,
            ...rest,
          })
        );
        appendAction.push(
          this.updateJobSpeciality({
            job_id,
            customer_uid,
            ...speciality,
          })
        );
        Promise.all(appendAction).then((res) => {
          this.getJobById(job_id);
          this.EditModal.isShowPopup = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.text-gray {
  color: darkgrey !important;
}
</style>
