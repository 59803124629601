var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CModal',{attrs:{"color":"primary","show":_vm.isShowPopup,"close-on-backdrop":false,"size":"lg"},on:{"update:show":function($event){_vm.isShowPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("Preferences Edit")]),_c('CButtonClose',{staticClass:"text-black",on:{"click":function($event){return _vm.modalCallBack(false)}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"px-4",attrs:{"type":"button","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.modalCallBack(false)}}},[_vm._v("Close")]),_c('CButton',{staticClass:"px-4",attrs:{"type":"button","color":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Save ")])]},proxy:true}])},[(!_vm.isFetching)?_c('LoadingOverlay'):_c('div',[_c('ValidationObserver',{ref:"editConsultantDetails",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"name":"editConsultantDetails"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',{staticClass:"mt-4"},[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(" Qualification Region ")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('Select',{attrs:{"name":"qualificationRegion","value":_vm.jobs.qualificationRegion,"options":_vm.options && _vm.options['qualification_region']
                        ? _vm.options['qualification_region']
                        : [],"taggable":false,"multiple":true,"clearable":true},on:{"input":_vm.handleMultiSelectArrayForCountries}})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Select one or more'),expression:"'Select one or more'"}],staticClass:"hinticon-multi-select",attrs:{"name":"cil-info"}})],1)])],1),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(" Qualification Country ")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('Select',{attrs:{"name":"qualificationCountry","value":_vm.jobs.qualificationCountry,"options":_vm.options && _vm.options['qualification_country']
                        ? _vm.options['qualification_country']
                        : [],"taggable":false,"multiple":true,"clearable":true},on:{"input":_vm.handleMultiSelectArrayForCountries}})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Select one or more'),expression:"'Select one or more'"}],staticClass:"hinticon-multi-select",attrs:{"name":"cil-info"}})],1)])],1)],1),_c('CRow',[(_vm.isPhysician || _vm.isNonClinicalAdmin)?_c('CCol',{staticClass:"mb-2",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12 d-flex"},[_vm._v(" Minimum Qualification Level Required ")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('Select',{attrs:{"name":"min_qualification_level_id","value":_vm.jobs.min_qualification_level_id,"options":_vm.options && _vm.options['min_qualification_level']
                        ? _vm.options['min_qualification_level']
                        : [],"taggable":false,"multiple":false,"clearable":true},on:{"input":_vm.handleChangeSelect}})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      placement: 'top',
                      content:
                        'Alert! This affects matching. Only select if a higher qualification is necessary.',
                      appendToBody: true,
                    }),expression:"{\n                      placement: 'top',\n                      content:\n                        'Alert! This affects matching. Only select if a higher qualification is necessary.',\n                      appendToBody: true,\n                    }"}],staticClass:"hinticon-multi-select my-1",attrs:{"name":"cil-info"}})],1)])],1):_vm._e(),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(" Minimum Years of Experience("+_vm._s(_vm.jobs.min_yrs_of_exp)+" yrs) ")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('v-slider',{attrs:{"name":"min_yrs_of_exp","min":0,"max":30,"value":_vm.jobs.min_yrs_of_exp || 0},on:{"change":_vm.handleSlider}})],1)])],1),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Languages")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"language_ids","value":_vm.jobs.language_ids,"options":_vm.options && _vm.options['language']
                          ? _vm.options['language']
                          : [],"taggable":false,"multiple":true,"clearable":true,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Select one or more'),expression:"'Select one or more'"}],staticClass:"hinticon-multi-select",attrs:{"name":"cil-info"}})],1)])],1)],1),_c('CRow',[(!_vm.isNonClinicalAdmin)?_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(" Local License or Eligibility Required ")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"regional_license","value":_vm.jobs.regional_license,"options":_vm.options && _vm.options['regional_license']
                        ? _vm.options['regional_license']
                        : []},on:{"change":_vm.handleChangeRadio}})],1)])],1):_vm._e(),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Gender")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"gender_id","value":_vm.jobs.gender_id,"options":_vm.options && _vm.options['gender'] ? _vm.options['gender'] : []},on:{"change":_vm.handleChangeRadio}})],1)])],1)],1),_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(" Preferred Nationality ")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('Select',{attrs:{"name":"countries_included","value":_vm.jobs.countries_included,"options":_vm.options && _vm.options['nationality']
                        ? _vm.options['nationality']
                        : [],"taggable":false,"multiple":true,"clearable":true},on:{"input":_vm.handleMultiSelectArrayForCountries}})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Select one or more'),expression:"'Select one or more'"}],staticClass:"hinticon-multi-select",attrs:{"name":"cil-info"}})],1)])],1),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(" Maximum age ("+_vm._s(_vm.jobs.maximum_age)+" yrs) ")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('v-slider',{attrs:{"name":"maximum_age","min":20,"max":70,"value":_vm.jobs.maximum_age},on:{"change":_vm.handleSliderMaxAge}})],1)])],1)],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }