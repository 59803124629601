<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Edit Job Information</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <ValidationObserver
          ref="editConsultantDetails"
          v-slot="{ handleSubmit }"
        >
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="editConsultantDetails"
          >
            <CRow>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="required col-lg-12 col-md-12">Job Title</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="job_title"
                        :value="jobs.job_title"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="required col-lg-12 col-md-12">
                    Candidate Type
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidateType"
                        :value="jobs.candidateType"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidateType']
                            ? options['candidateType']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="!isAHP">
                <CRow class="mb-3">
                  <label
                    name="lbl_first_name"
                    class="required col-lg-12 col-md-12"
                  >
                    Level
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="level_ids"
                        :value="jobs.level_ids"
                        @input="handleChangeSelect"
                        :options="
                          options && options['level_id']
                            ? options['level_id']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12">
                    {{
                      isAHP
                        ? "Requirement"
                        : isNonClinicAdmin
                        ? "Main Area of Expertise"
                        : "Speciality"
                    }}
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality"
                        :value="jobs.speciality"
                        @input="handleChangeSelect"
                        @change="resetSplInterest"
                        :options="
                          options && isNurse
                            ? options['nurseSubSpeciality']
                            : options['speciality']
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3" v-if="isNonClinicAdmin">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12 d-flex">
                    Other areas of expertise
                    <small class="text-primary ml-1 font-weight-bold">
                      (select max 3)
                    </small>
                    <div class="position-relative superscript-info">
                      <em
                        class="fas fa-circle-info superscript-info-icon"
                        v-c-tooltip="'Considered for Matching'"
                      />
                    </div>
                  </label>
                  <div class="col infoicon-multi-select">
                    <Select
                      name="other_areas_of_expertise_ids"
                      :value="jobs.other_areas_of_expertise_ids"
                      @input="handleChangeSelect"
                      :options="options ? options['speciality'] : []"
                      :taggable="false"
                      :multiple="true"
                      :clearable="false"
                      :limit="3"
                      noOptionsWhileSearch="No matching options, try similar keywords or email to support@talentfindsolutions.com"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select Maximum of 3'"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol
                md="6"
                v-if="
                  options['subSpeciality'] &&
                  options['subSpeciality'].length &&
                  !isNurse &&
                  !isNonClinicAdmin
                "
              >
                <CRow class="mb-3">
                  <label name="lbl_surname" class="col-lg-12 col-md-12">
                    {{ isAHP ? "Sub Type / License" : "Sub Speciality" }}
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="sub_speciality"
                      :value="jobs.sub_speciality"
                      @input="handleChangeSelect"
                      :options="
                        options && options['subSpeciality']
                          ? options['subSpeciality']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6" class="mb-3" v-if="isNurse || isAHP">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    Number of years in Speciality({{
                      jobs.experience_in_years || 0
                    }}
                    yrs)
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <v-slider
                      name="experience_in_years"
                      :min="0"
                      :max="20"
                      :value="jobs.experience_in_years || 0"
                      @change="handleSliderMinExpSpeciality"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3" v-if="isNurse">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    Other Areas of Expertise
                  </label>
                  <div class="col infoicon-multi-select">
                    <Select
                      name="other_areas_of_expertise_ids"
                      :value="jobs.other_areas_of_expertise_ids"
                      @input="handleChangeSelect"
                      :options="
                        options && options['nurseSubSpeciality']
                          ? options['nurseSubSpeciality']
                          : []
                      "
                      :taggable="false"
                      :multiple="true"
                      :clearable="false"
                      :limit="3"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select Maximum of 3'"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3" v-if="!(isAHP || isNonClinicAdmin)">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    {{
                      isNonClinicAdmin
                        ? "Areas of Expertise"
                        : "Special Interest(s)"
                    }}
                  </label>
                  <div class="col infoicon-multi-select">
                    <Select
                      name="special_interest_ids"
                      :value="jobs.special_interest_ids"
                      @input="handleChangeSelect"
                      :options="
                        options && options['special_interest']
                          ? options['special_interest']
                          : []
                      "
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                      noOptionsWhileSearch="No matching options, try similar keywords or email to support@talentfindsolutions.com"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <!-- Comment #1 NHS fields shown only for Nurse and for country UK -->
            <CRow
              v-if="
                isNurse &&
                jobs.location_id &&
                jobs.location_id.country_id == 182
              "
            >
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12 d-flex">
                    Current NHS Band
                    <div class="position-relative superscript-info">
                      <em
                        class="fas fa-circle-info superscript-info-icon"
                        v-c-tooltip="'Considered for Matching'"
                      />
                    </div>
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="current_nhs_band_ids"
                      :value="jobs.current_nhs_band_ids"
                      @input="handleMultiSelectArrayForCountries"
                      :options="
                        options && options['current_nhs_bands']
                          ? options['current_nhs_bands']
                          : []
                      "
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <!-- Comment #1 Ends -->
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import AddJob from "../../AddJobs/AddJob";
extend("required", { ...required, message: "This field is required" });
import { CONSTANT as CONST } from "@/helpers/helper";

export default {
  mixins: [AddJob],
  props: {
    jobInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CONST,
      profileData: {},
      payload: {},
      jobs: {
        current_nhs_band_ids: [{ label: "All", code: 0 }],
      },
    };
  },
  computed: {
    isFetching() {
      if (Object.keys(this.jobInfo).length) {
        let jobData = this.jobInfo;
        this.jobs = {
          ...this.jobInfo,
          organisation: jobData?.organisation
            ? {
                label: jobData?.organisation.name,
                code: jobData?.organisation.organisation_id,
                country_id: jobData?.organisation?.country_id,
              }
            : null,
          candidateType: jobData?.candidate_type
            ? {
                label: jobData?.candidate_type?.candidate_type,
                code: jobData?.candidate_type?.candidate_type_id,
              }
            : null,
          location_id: jobData.location_id
            ? {
                label: jobData.location?.location,
                code: jobData.location_id,
                country_id: jobData.location?.country_id,
              }
            : null,
          level_ids: jobData?.level_ids
            ? this.getLevelForSelectedId(jobData?.level_ids)
            : null,
          speciality: jobData.specialities?.length
            ? jobData.specialities
                ?.filter((val) => val.is_current || val.is_current === null)
                ?.map((val) => {
                  return {
                    label:
                      jobData.candidate_type_id == CONST.NURSE
                        ? `${val.speciality?.speciality}${
                            val.sub_speciality?.sub_speciality
                              ? ` - ${val.sub_speciality?.sub_speciality}`
                              : ""
                          }`
                        : val.speciality?.speciality,
                    code: val.speciality_id,
                    speciality_id: val?.speciality_id,
                    speciality_name: val?.speciality?.speciality,
                    sub_speciality_id: val?.sub_speciality_id,
                    sub_speciality: val?.sub_speciality?.sub_speciality,
                  };
                })[0]
            : null,
          sub_speciality:
            jobData.specialities
              ?.filter(
                (val) =>
                  (val.is_current || val.is_current === null) &&
                  val.sub_speciality_id
              )
              ?.map((val) => {
                return {
                  label:
                    jobData.candidate_type_id == CONST.NURSE
                      ? `${val.speciality?.speciality}${
                          val.sub_speciality?.sub_speciality
                            ? ` - ${val.sub_speciality?.sub_speciality}`
                            : ""
                        }`
                      : val.sub_speciality?.sub_speciality,
                  code: val.sub_speciality_id,
                  speciality_id: val?.speciality_id,
                  speciality_name: val?.speciality?.speciality,
                  sub_speciality_id: val?.sub_speciality_id,
                  sub_speciality: val?.sub_speciality?.sub_speciality,
                };
              })[0] || null,
          special_interest_ids:
            jobData.specialities
              ?.filter((fil) => fil.is_current || fil.is_current === null)[0]
              ?.job_special_interest?.map((val) => {
                return {
                  label: val.special_interest,
                  code: val.special_interest_id,
                  typeId: val.speciality_id,
                  candidateTypeId: val.candidate_type_id,
                };
              }) || null,
          job_speciality_id: jobData?.specialities?.filter(
            (val) => val.is_current == true || val.is_current == null
          )[0]?.job_speciality_id,
          experience_in_years: jobData?.specialities
            ? jobData?.specialities?.filter(
                (val) => val.is_current == true || val.is_current == null
              )[0]?.experience_in_years
            : null,
          other_areas_of_expertise_ids:
            jobData.specialities?.length > 1
              ? jobData.specialities
                  ?.filter((val) => val.is_current === false)
                  .map((val) => {
                    return {
                      label: `${val.speciality?.speciality}${
                        val.sub_speciality?.sub_speciality
                          ? ` - ${val.sub_speciality?.sub_speciality}`
                          : ""
                      }`,
                      code: val.speciality_id,
                      speciality_id: val?.speciality_id,
                      speciality_name: val?.speciality?.speciality,
                      sub_speciality_id: val?.sub_speciality_id,
                      sub_speciality: val?.sub_speciality?.sub_speciality,
                    };
                  })
              : [],
          current_nhs_band_ids: jobData.current_nhs_bands?.length
            ? jobData.current_nhs_bands.map((val) => {
                return {
                  label: val.band_name,
                  code: val.band_id,
                };
              })
            : [{ label: "All", code: 0 }],
        };
      }
      return true;
    },
    options() {
      return {
        candidateType: this.candidateType || [],
        level_id:
          this.candidateLevelByTypeAndCountry({
            candidateType: this.jobs?.candidateType?.code,
            country_id: this.jobs?.location_id?.country_id,
          }) || [],
        speciality:
          this.specialityByTypeAndCountry({
            candidateType: this.jobs?.candidateType?.code,
            country_id: null,
          }) || [],
        subSpeciality:
          this.subSpecialityFilterBySpeciality({
            speciality: this.jobs?.speciality?.code,
          }) || [],
        nurseSubSpeciality:
          this.nurseSubSpeciality(this.getNurseSubSpeciality) || [],
        special_interest: this.getFilteredSpecialInterest
          ? this.getFilteredSpecialInterest.filter(
              (val) => val?.code !== "Other"
            )
          : [],
        special_interest_for_ahp:
          this.specialInterestFilterAHP({
            candidate_type_id: this.jobs?.candidateType?.code,
            speciality: null,
          }) || [],
        current_nhs_bands: this.getCandidateBands || [],
      };
    },
    isNurse() {
      // Returns true if candidate type is nurse
      return this.jobs.candidateType?.code == CONST.NURSE;
    },
    isAHP() {
      // Returns true if candidate type is AHP
      return this.jobs.candidateType?.code == CONST.AHP;
    },
    // Returns if Candidate type is Non Clinic Admin
    isNonClinicAdmin() {
      return this.jobs.candidateType?.code == CONST.NON_CLINIC_ADMIN;
    },
  },
  methods: {
    handleSliderMinExpSpeciality: debounce(function (value) {
      Vue.set(this.jobs, "experience_in_years", value);
      Vue.set(this.payload, "experience_in_years", value);
    }, 500),
    async onSubmit() {
      const isValid = await this.$refs.editConsultantDetails.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (Object.keys(this.payload).length) {
        let finalPayload = {
          job_title: this.jobs.job_title,
          candidate_type_id: this.jobs.candidateType.code,
          level_ids: this.jobs.level_ids?.map((val) => val.code),
          speciality: {
            job_speciality_id: this.jobs.job_speciality_id,
            speciality_id: this.jobs.speciality?.code,
            sub_speciality_id: this.jobs.sub_speciality?.code || null,
            special_interest_ids: this.jobs.special_interest_ids?.length
              ? this.jobs.special_interest_ids?.map((val) => val.code)
              : null,
            experience_in_years: this.jobs.experience_in_years || null,
            other_areas_of_expertise_ids: this.jobs.other_areas_of_expertise_ids
              ?.length
              ? this.jobs.other_areas_of_expertise_ids?.map((val) => ({
                  speciality_id: val.speciality_id || val.code || null,
                  sub_speciality_id: val.sub_speciality_id || null,
                }))
              : null,
          },
          organisation_id: this.jobs.organisation?.code,
          job_id: this.jobs.job_id,
          current_nhs_band_ids:
            this.isNurse &&
            this.jobs.location_id.country_id == 182 &&
            this.jobs.current_nhs_band_ids[0].label != "All"
              ? this.jobs.current_nhs_band_ids?.map((val) => val.code)
              : null,
        };
        this.modalCallBack(true, finalPayload);
      } else {
        this.modalCallBack(false);
      }
    },
    resetSplInterest() {
      this.jobs.special_interest_ids = [];
      this.jobs.sub_speciality_id = null;
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    nurseSubSpeciality(data) {
      let nurseSubSpeciality = [];
      if (data?.length) {
        let ids = data.map((o) => o.speciality_id);
        nurseSubSpeciality = data.filter(
          ({ speciality_id }, index) => !ids.includes(speciality_id, index + 1)
        );
        nurseSubSpeciality = nurseSubSpeciality?.map((val) => ({
          label: val?.speciality_name,
          code: val?.speciality_id,
          sub_speciality_id: null,
          sub_speciality: "",
          speciality_id: val?.speciality_id,
          speciality_name: val?.speciality_name,
          isGroup: true,
        }));
      }
      data = [...data, ...nurseSubSpeciality];
      return data?.length
        ? this.sortByGroupLabel(data).map((v) => ({
            ...v,
            label: v?.isGroup
              ? `${v?.label} (All areas of expertise)`
              : v?.label,
          }))
        : [];
    },
    sortByGroupLabel(data) {
      return (
        data &&
        data.sort((a, b) => {
          const aLabel = a.label.toLowerCase();
          const bLabel = b.label.toLowerCase();
          if (aLabel < bLabel && a?.isGroup && !b?.isGroup) return -1;
          else if (aLabel > bLabel && b?.isGroup && !a?.isGroup) return 1;
          return 0;
        })
      );
    },
    sortByLabel(data) {
      return data?.sort((a, b) => {
        a = a.label.toLowerCase();
        b = b.label.toLowerCase();
        if (a < b) return -1;
        else if (a > b) return 1;
        return 0;
      });
    },
  },
};
</script>
