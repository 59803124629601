<template>
  <div class="top-line">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">Contact Information</h6>
      <div class="d-flex justify-content-end action-wrapper" v-if="isCandidate">
        <!-- <a
          class="px-2 btn"
          v-if="currentUserRole === Role.customerAdmin"
          @click="editContactInformation"
        >
          <i class="fas fa-pencil-alt" alt="pencil" />
        </a> -->
      </div>
    </div>
    <div
      v-if="job_contact_recruiters && job_contact_recruiters.length"
      class="recruiter pb-3"
    >
      <div v-for="(recruiter, r) in job_contact_recruiters" :key="r">
        <CRow v-if="isUKMainAgency" class="pb-2 m-0">
          <h6 class="name text-truncate">
            {{ recruiter_name(recruiter) }}<br>
            <em class="fas fa-hospital" style="margin-right: 10px"></em>
            {{ recruiter_org(recruiter) }}</h6>
        </CRow>
        <CRow v-else class="pb-2 m-0">
          <div class="d-flex justify-content-between">
            <h6 class="name">{{ recruiter_name(recruiter) || "--" }}</h6>
          </div>
        </CRow>
        <CRow class="pb-2 m-0">
          <div class="d-flex justify-content-between">
            <span>
              <i class="far fa-envelope mr-2"></i>
            </span>
            <div class="d-flex justify-content-start sticky-header">
              <span>
                <span class="text-black d-block">{{ recruiter_email(recruiter) || "--" }}</span>
                <span
                  class="text-black d-block"
                  v-if="recruiter_alternate_email"
                  >{{ recruiter_alternate_email }}</span
                >
              </span>
            </div>
          </div>
        </CRow>
        <CRow class="pb-2 m-0">
          <div class="d-flex justify-content-between">
            <span>
              <i class="fa fa-phone mr-2"></i>
            </span>
            <div class="d-flex justify-content-start sticky-header">
              <span>
                <span class="text-black d-block">{{ recruirter_phone(recruiter) || "--" }}</span>
                <span
                  class="text-black d-block"
                  v-if="recruiter_alternate_phone(recruiter)"
                  >{{ recruiter_alternate_phone(recruiter) }}</span
                >
              </span>
            </div>
          </div>
        </CRow>
        <CRow class="pb-2 m-0">
          <span>
            <i class="fab fa-whatsapp mr-2"></i>
            <span class="text-black">{{ "--" }}</span>
          </span>
        </CRow>
      </div>
    </div>
    <div v-if="!isCandidateSupplierFromAccessToken" class="requester">
      <CRow v-if="isUKMainAgency" class="pb-2 m-0">
        <h6 class="name text-truncate">{{requester_name}}<br>
        <em class="fas fa-hospital" style="margin-right: 10px"></em>
        {{requester_org}}</h6>
        </CRow>
      <CRow v-else class="m-0">
        <div class="d-flex justify-content-between">
          <h5 class="name">{{ requester_name }}</h5>
        </div>
      </CRow>
      <CRow class="m-0 pb-2">
        <div class="d-flex justify-content-between">
          <h6 class="department">
            {{ requester_department !== "--" ? " Department of " + requester_department : "Department - N/A"}}
          </h6>
        </div>
      </CRow>
      <CRow class="pb-2 m-0">
        <div class="d-flex justify-content-between">
          <span>
            <i class="far fa-envelope mr-2"></i>
          </span>
          <div class="d-flex justify-content-start sticky-header">
            <span>
              <span class="text-black d-block">{{ requester_email }}</span>
              <span
                class="text-black d-block"
                v-if="requester_alternate_email"
                >{{ requester_alternate_email }}</span
              >
            </span>
          </div>
        </div>
      </CRow>
      <CRow class="pb-2 m-0">
        <div class="d-flex justify-content-between">
          <span>
            <i class="fa fa-phone mr-2"></i>
          </span>
          <div class="d-flex justify-content-start sticky-header">
            <span>
              <span class="text-black d-block">{{ requester_phone }}</span>
              <span
                class="text-black d-block"
                v-if="requester_alternate_phone"
                >{{ requester_alternate_phone }}</span
              >
            </span>
          </div>
        </div>
      </CRow>
      <CRow class="pb-2 m-0">
        <span>
          <i class="fab fa-whatsapp mr-2"></i>
          <span class="text-black">{{ whatsapp }}</span>
        </span>
      </CRow>
    </div>
    <div v-if="isCandidate">
      <CRow class="pb-2 m-0">
        <span>
          <i class="fab fa-skype mr-2"></i>
          <span class="text-black">{{ skype_id }}</span>
        </span>
      </CRow>
      <CRow class="pb-2 m-0" v-if="isCandidate">
        <div class="d-flex justify-content-between">
          <span>
            <i class="fas fa-map-marker-alt mr-2"></i>
          </span>
          <div class="d-flex justify-content-start sticky-header">
            <span>{{ currentLocation || "--" }}</span>
          </div>
        </div>
      </CRow>
    </div>
    <div v-if="!isCandidateSupplierFromAccessToken" class="center position-bottom py-2">
      <CButton color="primary" @click="openCommentModal()">
        Private Comments ({{ getComments.length }})
      </CButton>
    </div>
    <ContactInformationEdit
      :jobInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="contactInformationModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <JobCommentModal
      v-if="commentModal.isShowPopup"
      :isShowPopup="commentModal.isShowPopup"
      :jobID="commentModal.jobID"
      @commentModalCallBack="commentModalCallBack"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import ContactInformationEdit from "@/containers/JobsDetailedView/EditForm/ContactInformationEdit";
import JobCommentModal from "@/containers/JobsDetailedView/JobCommentModal";
export default {
  props: {
    isHospital: {
      type: Boolean,
      default: false,
    },
    isSideBar: {
      type: Boolean,
      default: false,
    },
    isCandidate: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      commentModal: {
        isShowPopup: false,
        jobID: null,
      },
    };
  },
  components: {
    ContactInformationEdit,
    JobCommentModal,
  },
  computed: {
    ...mapGetters([
      "getSelectedCandidateDataForJobsList",
      "getJobDetailsById",
      "locationCountryInfo",
      "getJobCommonComments",
      "isCandidateSupplierFromAccessToken",
      "isUKMainAgency"
    ]),
    // Job requester are the POC (requester)
    requester_name() {
      return this.profile?.job_contact?.name
        ? `${this.profile?.job_contact?.name} (Requester)`
        : "--";
    },
    requester_org() {
      return this.profile?.job_contact?.user?.org_detail?.org_name;
    },
    requester_department() {
      return this.profile?.job_contact?.department?.department_name || "--";
    },
    requester_email() {
      return this.profile?.job_contact?.email || "--";
    },
    requester_alternate_email() {
      return null;
    },
    requester_phone() {
      return this.profile?.job_contact?.phone_no?.replace("^", "-") || "--";
    },
    requester_alternate_phone() {
      return this.profile?.job_contact?.alt_phone_no?.replace("^", "-") || null;
    },
    recruiter_alternate_email() {
      return null;
    },
    whatsapp() {
      return "--";
    },
    skype_id() {
      return this.profile?.job_contact?.skype || "--";
    },
    address_line_1() {
      return this.profile?.job_contact?.address || "--";
    },
    currentLocation() {
      return (
        this.locationCountryInfo &&
        this.locationCountryInfo.length &&
        this.profile?.organisation?.country?.country_id &&
        this.locationCountryInfo.filter(
          (val) => val.code === this.profile?.organisation?.country?.country_id
        )[0].label
      );
    },
    getComments() {
      return this.getJobCommonComments;
    },
    job_contact_recruiters() {
      return this.profile?.job_contact_recruiters
        ? this.profile?.job_contact_recruiters
        : [];
    },
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "initFetchContactInfoOptions",
      "fetchJobCommonComments",
    ]),
    recruirter_phone(data) {
      return (
        data?.customer_recruiter?.user.contact_phone?.replace("^", "-") || null
      );
    },
    recruiter_alternate_phone(data) {
      return (
        data?.customer_recruiter?.alternate_phone?.replace("^", "-") || null
      );
    },
    editContactInformation() {
      this.EditModal.isShowPopup = true;
    },
    recruiter_name(data) {
      const first_name = data?.customer_recruiter?.user?.first_name;
      const last_name = data?.customer_recruiter?.user?.surname;
      return first_name && last_name
        ? `${first_name} ${last_name} (Recruiter) `
        : "--";
    },
    recruiter_org(data) {
      return data?.customer_recruiter?.user?.org_detail?.org_name;
    },
    recruiter_email(data) {
      return data?.customer_recruiter?.user?.email || "--";
    },
    contactInformationModalCallBack(action, data) {
      if (action === false) this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        // let appentAction = this.updateCandidate({ data, customer_uid });
        // Promise.all([appentAction]).then((res) => {
        //   if (res)
        this.EditModal.isShowPopup = false;
        // });
      }
    },
    openCommentModal() {
      this.commentModal.jobID = this.profile?.job_id;
      this.commentModal.isShowPopup = true;
    },
    commentModalCallBack() {
      this.commentModal.isShowPopup = false;
    },
  },
  mounted() {
    this.initFetchContactInfoOptions();
    this.fetchJobCommonComments(this.profile?.job_id);
  },
};
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
}
.name {
  font-weight: bold;
  opacity: 0.9;
  font-size: 0.9rem;
  color: #3c4b64;
}
.department {
  opacity: 0.9;
  font-size: 0.8rem;
  color: #3c4b64;
}
.position-bottom {
  padding-top: 90px !important;
}
</style>
