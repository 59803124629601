<template>
  <div>
    <div class="cand-info-img text-center m-auto pb-1">
      <img
        :src="iconUrl"
        onerror="this.src='/img/hospital.png';"
        class="rounded-circle ratio-1"
        alt
      />
    </div>
    <div v-if="!isCandidateSupplierFromAccessToken" class="text-center">
      <h5 class="m-0">
        {{ organisation }}
      </h5>
      <div class="block2 pl-1 mb-2 mt-2">
        <span class="text-primary fs-25">
          <i class="fas fa-map-marker-alt text-gray"></i>
        </span>
        <span class="pl-1">
          <span>{{ organisationLocation }} | {{ displayLocation }}</span>
        </span>
      </div>
      <div class="job-label reference">
        <label class="setfont">Job ID : {{ jobReferenceId }}</label
        >&nbsp;&nbsp;&nbsp;
        <span v-if="jobDetail.featured" class="text-gold">
          <em class="fa fa-star"></em>
        </span>
        <span v-else class="text-secondary">
          <em class="fa fa-star"></em>
        </span>
        <span
          v-if="jobDetail.is_private_job"
          class="p-1"
          v-c-tooltip="{ content: 'Private Job', appendToBody: true }"
          style="color: #696969"
        >
          <em class="fa fa-lock"></em>
        </span>
      </div>
      <div class="job-label">
        <label>Advertised:</label>&nbsp;&nbsp;&nbsp;&nbsp;
        <span
          v-if="
            jobDetail.job_status.status == 'Active' && !jobDetail.is_private_job
          "
          class="status"
          style="color: green"
        >
          <em class="fas fa-check-circle"></em>
          {{ publishStatus }}
        </span>
        <span
          v-else-if="jobDetail.job_status.status == 'Inactive'"
          class="status"
          style="color: red"
        >
          <em class="fas fa-times-circle"></em>
          {{ publishStatus }}
        </span>
        <span
          v-else-if="jobDetail.job_status.status == 'Pending'"
          class="status"
          style="color: orange"
        >
          <em class="fas fa-business-time"></em>
          {{ publishStatus }}
        </span>
        <span
          v-else-if="
            jobDetail.job_status.status == 'Draft' || jobDetail.is_private_job
          "
          class="status"
          style="color: grey"
        >
          <em class="fas fa-archive"></em>
          {{ publishStatus }}
        </span>
      </div>
      <div class="job-label">
        <label>Posted Date:</label>&nbsp;&nbsp;
        <span class="text-primary bold">{{
          jobDetail.created_on | postedDate
        }}</span>
      </div>
      <div class="job-label expiry">
        <label>Expiry:</label>&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="text-primary bold"
          >{{ expirydate }} / {{ expiryDays }}</span
        >
      </div>
      <div class="job-label">
        <CButton color="primary" class="px-2 f-12" @click="previewJob">
          Preview
        </CButton>
      </div>
    </div>
    <div v-else class="text-center">
      <h5 class="m-0">
        {{ organisation }}
      </h5>
      <p>{{ organisationLocation }}</p>
      <div class="job-label reference">
        <!-- <label class="setfont">Job ID : {{ referenceId }}</label -->
        <label class="setfont">Job ID : {{ jobReferenceId }}</label
        >&nbsp;&nbsp;&nbsp;
        <span v-if="jobDetail.featured" class="text-gold">
          <em class="fa fa-star"></em>
        </span>
        <span v-else class="text-secondary">
          <em class="fa fa-star"></em>
        </span>
      </div>
      <div v-if="isCandidateSupplierFromAccessToken" class="text-center">
        <div class="job-label">
          <label>Allocated on:</label>&nbsp;&nbsp;
          <span class="text-primary bold">{{
            jobAllocatedOn | formatDate
          }}</span>
        </div>
      </div>
    </div>
    <JobCardPreviewModal
      :isShowPopup="previewModal"
      :onCancelPreview="onCancelPreview"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
export default {
  props: {
    jobDetail: {
      type: Object,
      default: null,
    },
  },
  components: {
    JobCardPreviewModal,
  },
  data() {
    return {
      previewModal: false,
    };
  },
  computed: {
    ...mapGetters(["isCandidateSupplierFromAccessToken"]),
    iconUrl() {
      if (this.isCandidateSupplierFromAccessToken) {
        return (
          this.jobDetail?.parent_org_info[0]?.logo_url || "/img/hospital.png"
        );
      }
      return this.jobDetail?.organisation?.logo_url || "/img/hospital.png";
    },
    organisation() {
      if (this.isCandidateSupplierFromAccessToken) {
        return this.jobDetail?.parent_org_info[0]?.name || null;
      }
      return this.jobDetail?.organisation?.name || null;
    },
    organisationLocation() {
      return this.jobDetail.location?.location || "--";
    },
    jobReferenceId() {
      return `${this.jobDetail?.display_uid}` || "--";
    },
    publishStatus() {
      if (this.jobDetail?.is_private_job) return "Draft";
      if (this.jobDetail.job_status.status == "Active") return "Published";
      return this.jobDetail.job_status.status;
    },
    expirydate() {
      return moment(this.jobDetail.expiry_review_date).format("DD-MM-YYYY");
    },
    expiryDays() {
      let days = this.jobDetail.expiry_review_date
        ? this.DaysDiff(this.jobDetail.expiry_review_date) < 0
          ? "Expired"
          : this.DaysDiff(this.jobDetail.expiry_review_date) + "\xa0" + "Days"
        : 0;
      return days;
    },
    jobAllocatedOn() {
      return this.jobDetail?.allocated_data[0]?.created_on;
    },
    displayLocation(){
      return this.jobDetail?.display_location;
    }
  },
  methods: {
    ...mapActions(["selectedJobBoard"]),
    previewJob() {
      const id = this.jobDetail.job_id;
      this.selectedJobBoard(id);
      this.previewModal = true;
    },
    DaysDiff(expiryDate) {
      let expiration = moment(expiryDate);
      let current_date = moment();
      return expiration.diff(current_date, "days");
    },
    onCancelPreview() {
      this.previewModal = false;
    },
  },
  filters: {
    postedDate(postdate) {
      return moment(postdate).format("DD-MM-YYYY");
    },
    formatDate(data) {
      if (data) return moment(data).format("DD MMM YYYY");
      return "--";
    },
  },
};
</script>