<template>
  <div v-if="re_render_the_html_content">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        Salary & Benefits
        <CIcon
          name="cil-info"
          class="hinticon-multi-select"
          v-c-tooltip="' See Details Section below for Additional Details'"
        />
        <em
          class="fas fa-angle-double-down"
          style="cursor: pointer"
          @click="$parent.goToSalaryAndBenefitsSection()"
        ></em>
      </h6>
    </div>
    <CRow class="pb-2">
      <CCol>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Salary Range</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black"
              >{{
                profile.salary_min && profile.salary_max
                  ? `${
                      profile.salary_min_text || profile.salary_min
                    } ${salary_currency} to ${
                      profile.salary_max_text || profile.salary_max
                    } ${salary_currency}`
                  : "--"
              }}
            </span>
            <span
              v-if="isSalaryRangePublished"
              v-c-tooltip="'Salary displayed in public job advert'"
              class="status"
              style="color: green"
            >
              <em class="fas fa-check-circle"></em>
            </span>
            <span
              v-if="!isSalaryRangePublished"
              v-c-tooltip="'Salary not displayed in public job advert'"
              class="status"
              style="color: grey"
            >
              <em class="fas fa-times-circle"></em>
            </span>
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Target Salary</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black"
              >{{
                profile.target_salary_text || profile.target_salary || "--"
              }}
              {{ target_currency }}</span
            >
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Total Budget</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">
              {{ profile && profile.allocated_budget || "--" }}
              {{ total_budget_currency }}
            </span>
          </CCol>
        </CRow>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">No. of positions Available</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">
              {{ profile && profile.positions_available || "--" }}
            </span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <h6 class="text-primary">Reviewer(s)</h6>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ recruiter_names }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <h6 class="text-primary">Interviewer(s)</h6>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ interviewer_names }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <h6 class="text-primary">Approver(s)</h6>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ approver_names }}</span>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import { mapGetters } from "vuex";
export default {
  props: {
    profile: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      re_render_the_html_content: true,
    };
  },
  computed: {
    ...mapGetters(["getCurrencyForSelectedID"]),
    target_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.target_salary_currency_id
      ).map((val) => val.label)[0];
    },
    salary_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.salary_currency_id
      ).map((val) => val.label)[0];
    },
    recruiter_names() {
      const fname = this.profile?.job_candidate_users
        ?.filter((val) => val.customer_user?.customer_user_type_id == 1)
        ?.map(
          (val) =>
            `${val.customer_user?.user?.first_name} ${
              val.customer_user?.user?.surname
            } ${
              val.customer_user?.user?.org_detail?.org_name
                ? `(${val.customer_user?.user?.org_detail?.org_name})`
                : ""
            }`
        );

      return fname?.length ? fname.join(", ") : null || "--";
    },
    interviewer_names() {
      const fname = this.profile?.job_candidate_users
        ?.filter((val) => val.customer_user?.customer_user_type_id == 2)
        ?.map(
          (val) =>
            `${val.customer_user?.user?.first_name} ${
              val.customer_user?.user?.surname
            } ${
              val.customer_user?.user?.org_detail?.org_name
                ? `(${val.customer_user?.user?.org_detail?.org_name})`
                : ""
            }`
        );
      return fname?.length ? fname.join(", ") : null || "--";
    },
    approver_names() {
      const fname = this.profile?.job_users
        ?.filter((val) => val.customer_user?.customer_user_type_id == 4)
        ?.map(
          (val) =>
            `${val.customer_user?.user?.first_name} ${
              val.customer_user?.user?.surname
            } ${
              val.customer_user?.user?.org_detail?.org_name
                ? `(${val.customer_user?.user?.org_detail?.org_name})`
                : ""
            }`
        );
      return fname?.length ? fname.join(", ") : null || "--";
    },
    isSalaryRangePublished() {
      return this.profile?.preferred_in_advert?.includes("salary")
        ? true
        : false;
    },
    total_budget_currency() {
      return this.getCurrencyForSelectedID(
        this.profile?.allocated_budget_currency_id
      ).map((val) => val.label)[0];
    },
  },
  watch: {
    // This function is re render the html content which updates tooltip properly
    isSalaryRangePublished() {
      this.re_render_the_html_content = false;
      setTimeout(() => {
        this.re_render_the_html_content = true;
      }, 100);
    },
  },
};
</script>
<style scoped>
.text-gray {
  color: darkgray !important ;
}
</style>
