<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Preferences Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()">
          Save
        </CButton>
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <ValidationObserver
          ref="editConsultantDetails"
          v-slot="{ handleSubmit }"
        >
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="editConsultantDetails"
          >
            <CRow class="mt-4">
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    Qualification Region
                  </label>
                  <div class="col infoicon-multi-select">
                    <Select
                      name="qualificationRegion"
                      :value="jobs.qualificationRegion"
                      @input="handleMultiSelectArrayForCountries"
                      :options="
                        options && options['qualification_region']
                          ? options['qualification_region']
                          : []
                      "
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    Qualification Country
                  </label>
                  <div class="col infoicon-multi-select">
                    <Select
                      name="qualificationCountry"
                      :value="jobs.qualificationCountry"
                      @input="handleMultiSelectArrayForCountries"
                      :options="
                        options && options['qualification_country']
                          ? options['qualification_country']
                          : []
                      "
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <!--Comment #9 Min Qual is shown only for Candidate Type - Physician and NonClinicalAdmin-->
              <!-- comment #10 Show consider for matching checkbox only when min_qualification_level_id is selected -->
              <CCol
                md="6"
                class="mb-2"
                v-if="isPhysician || isNonClinicalAdmin"
              >
                <CRow class="row">
                  <label class="col-lg-12 col-md-12 d-flex">
                    Minimum Qualification Level Required
                  </label>
                  <div class="col infoicon-multi-select">
                    <Select
                      name="min_qualification_level_id"
                      :value="jobs.min_qualification_level_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['min_qualification_level']
                          ? options['min_qualification_level']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select my-1"
                      v-c-tooltip="{
                        placement: 'top',
                        content:
                          'Alert! This affects matching. Only select if a higher qualification is necessary.',
                        appendToBody: true,
                      }"
                    />
                  </div>
                </CRow>
              </CCol>
              <!-- comment #9 Shown only for Candidate Type - Physician Ends-->
              <!-- Comment #10 Ends -->
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    Minimum Years of Experience({{ jobs.min_yrs_of_exp }}
                    yrs)
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <v-slider
                      name="min_yrs_of_exp"
                      :min="0"
                      :max="30"
                      :value="jobs.min_yrs_of_exp || 0"
                      @change="handleSlider"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Languages</label>
                  <div class="col infoicon-multi-select">
                    <ValidationProvider v-slot="{ errors }">
                      <Select
                        name="language_ids"
                        :value="jobs.language_ids"
                        @input="handleChangeSelect"
                        :options="
                          options && options['language']
                            ? options['language']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol v-if="!isNonClinicalAdmin" md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    Local License or Eligibility Required
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="regional_license"
                      :value="jobs.regional_license"
                      :options="
                        options && options['regional_license']
                          ? options['regional_license']
                          : []
                      "
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Gender</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <RadioButton
                      name="gender_id"
                      :value="jobs.gender_id"
                      :options="
                        options && options['gender'] ? options['gender'] : []
                      "
                      @change="handleChangeRadio"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <CRow>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    Preferred Nationality
                  </label>
                  <div class="col infoicon-multi-select">
                    <Select
                      name="countries_included"
                      :value="jobs.countries_included"
                      @input="handleMultiSelectArrayForCountries"
                      :options="
                        options && options['nationality']
                          ? options['nationality']
                          : []
                      "
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">
                    Maximum age ({{ jobs.maximum_age }} yrs)
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <v-slider
                      name="maximum_age"
                      :min="20"
                      :max="70"
                      :value="jobs.maximum_age"
                      @change="handleSliderMaxAge"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
const debounce = require("lodash.debounce");
import AddJob from "@/containers/AddJobs/AddJob";
import { CONSTANT as CONST } from "@/helpers/helper";

export default {
  mixins: [AddJob],
  props: {
    jobInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profileData: {},
      payload: {},
      jobs: {},
    };
  },
  computed: {
    isFetching() {
      if (Object.keys(this.jobInfo).length) {
        let jobData = this.jobInfo;
        this.jobs = {
          ...this.jobInfo,
          organisation: jobData?.organisation
            ? {
                label: jobData?.organisation.name,
                code: jobData?.organisation.organisation_id,
              }
            : null,
          qualificationRegion: jobData?.qualification_region_ids
            ? this.getRegionNameForSelectedId(jobData?.qualification_region_ids)
            : [{ label: "All", code: 0 }],
          qualificationCountry: jobData?.qualification_country_ids
            ? this.getCountryNameForSelectedId(
                jobData?.qualification_country_ids
              )
            : [{ label: "All", code: 0 }],
          min_qualification_level_id: jobData.min_qualification_level_id
            ? {
                label:
                  jobData.min_qualification_level?.qualification_classification,
                code: jobData.min_qualification_level
                  ?.qualification_classification_id,
              }
            : null,
          gender_id: jobData?.gender_id || null,
          countries_included: jobData?.countries_included?.length
            ? jobData.countries_included.map(({ country }) => {
                return {
                  label: country.country_name,
                  code: country.country_id,
                };
              })
            : [{ label: "All", code: 0 }],
          regional_license: jobData?.regional_license || "n/a",
          language_ids: jobData?.language_ids
            ? this.getLanguageForSelectedId(jobData.language_ids)
            : null,
          min_yrs_of_exp: jobData?.min_yrs_of_exp || 0,
        };
      }
      return true;
    },
    options() {
      return {
        qualification_region:
          this.sortOrganisationRegions(this.getOrganisationRegions) || [],
        qualification_country: this.getQualificationCountryByRegion,
        min_qualification_level:
          this.getQualificationClassification(this.jobs.candidate_type_id) ||
          [],
        gender: [
          { id: 1, label: "Male" },
          { id: 2, label: "Female" },
          { id: null, label: "Any" },
        ],
        regional_license: [
          { id: "required", label: "Required" },
          { id: "preferred", label: "Preferred" },
          { id: "n/a", label: "N/A" },
        ],
        nationality:
          [
            { code: 0, label: "All" },
            ...this.getNationalityCountryInfo.map(
              ({ country_name, country_id }) => ({
                code: country_id,
                label: country_name,
              })
            ),
          ] || [],
        language: this.locationLanguageReOrder,
      };
    },
    locationLanguageReOrder() {
      return (
        this.locationLanguage &&
        this.locationLanguage.length &&
        this.locationLanguage.sort((a, b) => {
          return a.code === 1 ? -1 : 1;
        })
      );
    },
    isNonClinicalAdmin() {
      return this.jobs.candidate_type_id == CONST.NON_CLINIC_ADMIN;
    },
    isPhysician() {
      return this.jobs.candidate_type_id == CONST.PHYSICIAN;
    },
  },
  watch: {
    getQualificationCountryByRegion() {
      let data = [];
      if (this.jobs.qualificationCountry?.length) {
        this.jobs.qualificationCountry.forEach((val) => {
          let hasFound = this.options.qualification_country.some(
            (options) => options.code == val.code
          );
          if (hasFound) data.push(val);
        });
        this.handleMultiSelectArrayForCountries("qualificationCountry", data);
      }
    },
  },
  methods: {
    sortOrganisationRegions(data) {
      if (data) {
        let tierData = [];
        let otherData = [];
        data.forEach((val) => {
          if (val.code == 18 || val.code == 19 || val.code == 20)
            tierData.push(val);
          else otherData.push(val);
        });
        data = tierData.concat(otherData);
        return [{ code: 0, label: "All" }, ...data];
      }
    },
    handleSliderMaxAge: debounce(function (value) {
      Vue.set(this.jobs, "maximum_age", value);
      Vue.set(this.payload, "maximum_age", value);
    }, 500),
    handleSlider: debounce(function (value) {
      Vue.set(this.jobs, "min_yrs_of_exp", value);
      Vue.set(this.payload, "min_yrs_of_exp", value);
    }, 500),
    handleCheckBox(name, value) {
      Vue.set(this.payload, name, value);
    },
    async onSubmit() {
      const isValid = await this.$refs.editConsultantDetails.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      if (Object.keys(this.payload).length) {
        let finalPayload = {
          job_id: this.jobs?.job_id,
          customer_uid: this.jobs?.customer_uid,
          organisation_id: this.jobs.organisation?.code,
          qualification_region_ids:
            this.jobs?.qualificationRegion[0].label != "All"
              ? this.jobs?.qualificationRegion?.map((val) => val.code)
              : null,
          qualification_country_ids:
            this.jobs?.qualificationCountry[0].label != "All"
              ? this.jobs?.qualificationCountry?.map((val) => val.code)
              : null,
          qualification_regions_all:
            this.jobs?.qualificationRegion[0].label != "All" ? null : true,
          qualification_countries_all:
            this.jobs?.qualificationCountry[0].label != "All" ? null : true,
          min_qualification_level_id:
            this.isPhysician || this.isNonClinicalAdmin
              ? this.jobs.min_qualification_level_id?.code || null
              : null,
          gender_id: this.jobs?.gender_id || null,
          countries_included:
            this.jobs?.countries_included[0].label != "All"
              ? this.jobs?.countries_included
              : null,
          language_ids: this.jobs?.language_ids?.map((val) => val.code),
          regional_license: this.jobs?.regional_license,
          min_yrs_of_exp: this.jobs?.min_yrs_of_exp,
          maximum_age: this.jobs.maximum_age || null,
        };
        this.modalCallBack(true, finalPayload);
      } else {
        this.modalCallBack(false);
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
};
</script>
