<template>
  <div>
    <CCard class="candidate-card" style="min-height: 400px">
      <CCardBody>
        <div class="content-job-title mb-3">
          <div class="image-wrapper">
            <img
              :src="getProfilePic(candidateInfo)"
              class="rounded-circle"
              alt="DP"
              @error="imageLoadOnError"
            />
          </div>
          <span class="d-block">
            <h5
              class="m-0 mt-2"
              :style="`color: ${getGenderColor(candidateInfo.gender)}`"
            >
              <a
                class="cursor-action"
                @click="navigateToCandidateDetail(candidateInfo)"
              >
                {{ toTitleCase(candidateInfo.first_name) }}
                {{ toTitleCase(candidateInfo.surname) }}
              </a>
            </h5>
          </span>
          <span class="d-block">
            <span class="text-muted">
              {{ candidateInfo.candidate_type.candidate_type }}
            </span>
          </span>
          <span class="d-block">
            <span class="text-muted">
              ID: {{ candidateInfo.display_uid }}
            </span>
          </span>
          <span
            class="d-block"
            v-if="candidateInfo.speciality || candidateInfo.qualifications"
          >
            {{ speciality(candidateInfo) }}
          </span>
          <!-- <span class="d-block">{{ getCountry(candidateInfo) }}</span> -->
          <p class="mt-1">
            <strong>Job:</strong>
            <a
              class="cursor-action link"
              @click="navigateToJobListPreview(item)"
            >
              {{ item.job_title }} - {{ item.job_display_uid }}
            </a>
          </p>
        </div>
        <br />
        <div class="candidate-status">
          <CBadge
            v-if="
              item.candidate.agency_contact &&
              item.candidate.organisation_name &&
              isUKMainAgency &&
              currentUserRole !== Role.systemAdmin
            "
            color="danger"
            class="font-weight-normal mr-1"
            >{{ `Agency - ${item.candidate.organisation_name}` }}</CBadge
          >
          <div v-else>
            <CBadge
              v-for="org in item.candidate.shared_organisations"
              :key="org.id"
              color="wintergreen"
              class="font-weight-normal mr-1 text-white"
            >
              {{ org.name }}
            </CBadge>
          </div>
          <CBadge
            color="success"
            class="font-weight-normal mr-1"
            v-if="job_applied"
          >
            <span>Applied</span>
          </CBadge>
          <CBadge color="primary" class="font-weight-normal mr-1" v-else>
            <span>Talent Pool</span>
          </CBadge>
          <CBadge
            :color="getStatusColor(item.status_id)"
            class="font-weight-normal mr-1 text-white"
            v-if="item.status && item.status['display_text']"
          >
            <span>{{ item.status["display_text"] }}</span>
          </CBadge>
          <CBadge
            :color="getStatusColor(item.sub_status_id)"
            class="font-weight-normal mr-1 text-white"
            v-if="item.sub_status && item.sub_status['display_text']"
          >
            <span>{{ item.sub_status["display_text"] }}</span>
          </CBadge>
          <CBadge
            color="danger"
            class="font-weight-normal mr-1"
            v-if="job_rejected"
          >
            <span>Rejected</span>
          </CBadge>
        </div>
        <div class="position-absolute" style="top: 10px; right: 15px">
          <!-- <CButton
            class="p-0 mx-1 text-primary cursor-pointer"
            v-c-tooltip="{
              content: 'Add to Scratch Pad',
              appendToBody: true,
              placement: 'left',
            }"
          >
            <i
              class="fa-solid fa-circle-plus m-0 fs-14"
              @click="addToScratch"
            ></i>
          </CButton> -->
          <CDropdown color="primary" class="mx-2 w-100" :caret="false">
            <template #toggler>
              <span class="text-primary cursor-pointer">
                <i class="fa-solid fa-circle-plus fs-14"></i>
              </span>
            </template>
            <CDropdownItem
              href="javascript:void(0)"
              @click="openShortListModal(item)"
              >Shortlist</CDropdownItem
            >
            <CDropdownItem href="javascript:void(0)" @click="addToScratch"
              >Add to Scratchpad</CDropdownItem
            >
          </CDropdown>
        </div>
        <div class="position-absolute d-flex" style="top: 10px; left: 15px">
          <CButton
            colour="primary"
            class="text-primary p-0 mx-1 d-flex"
            :disabled="!candidateInfo.cv_document"
            @click="previewCV(candidateInfo)"
            v-c-tooltip="{ content: 'View CV/Resume', appendToBody: true }"
          >
            <i class="fa-solid fa-eye m-0 fs-14"></i>
          </CButton>
          <CButton
            colour="primary"
            class="btn-link p-0 mx-1 d-flex"
            :disabled="!candidateInfo.cv_document"
            :href="candidateInfo.cv_document"
            download
            target="_blank"
            v-c-tooltip="{
              content: 'Download CV/Resume',
              appendToBody: true,
            }"
          >
            <i class="fa-solid fa-download m-0 fs-14"></i>
          </CButton>
          <span
            class="d-flex align-items-end text-primary"
            v-if="
              item.reference_type_id && [4, 3].includes(item.reference_type_id)
            "
          >
            <i
              class="fa-solid fa-circle-info m-0 fs-14"
              v-c-tooltip="{
                content: `${item.matched_comments}`,
                appendToBody: true,
              }"
            ></i>
          </span>
        </div>
      </CCardBody>
    </CCard>
    <ScratchPadModal
      v-if="scratchpad.isShowPopup"
      :todo="scratchpad.todo"
      :isShowPopup="scratchpad.isShowPopup"
      @modalCallBack="scratchPadModalCallBack"
    />
    <PreviewModal
      v-if="previewModal.isShowPopup"
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <ShortListModal
      v-if="shortListModal.isShowPopup"
      :isShowPopup="shortListModal.isShowPopup"
      :candidateDetail="shortListModal.candidateDetail"
      @modalCallBack="shortListModalCallBack"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import ScratchPadModal from "@/containers/Dashboard/RecruiterDashBoard/ScratchPadModal";
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import ShortListModal from "@/components/reusable/ShortList/ShortListModal";
export default {
  name: "CandidateInfoCard",
  props: ["item"],
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      scratchpad: {
        isShowPopup: false,
        todo: {},
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      shortListModal: {
        isShowPopup: false,
        candidateDetail: null,
      },
    };
  },
  components: {
    ScratchPadModal,
    PreviewModal,
    ShortListModal,
  },
  computed: {
    ...mapGetters([
      "getGenderColor",
      "isUKMainAgency", // pertemps
      "isCandidateSupplierFromAccessToken", // pertemps
      "isJobSupplierFromAccessToken", //pertemps
      "getCustomerId",
      "getUserId",
    ]),
    candidateInfo() {
      return this.item?.candidate;
    },
    job_applied() {
      return this.item?.job_applied;
    },
    job_rejected() {
      return this.item?.is_accepted === false || false;
    },
  },
  methods: {
    ...mapActions([
      "setCandidateListBackUrl",
      "resetCandidateDetail",
      "postScratchPad",
    ]),
    getStatusColor(statusId) {
      switch (statusId) {
        case 39:
          return "success";
        case 42:
          return "matched";
        case 68:
          return "info";
        case 54:
          return "interview-status";
        case 62:
          return "onboarding-status";
        case 52:
          return "submitted-status";
        case 58:
          return "low";
        case 69:
          return "approved-onboarding";
        case 57:
          return "interview-completed";
        case 55:
          return "warning";
        case 53:
          return "awaiting-feedback";
        case 48:
          return "withdrawn";
        case 77:
          return "possible";
        case 41:
          return "manual";
        case 76:
          return "definite";
        case 71:
          return "inreview";
        case 59:
          return "awaiting-reponse";
        case 63:
          return "joined";
        case 47:
          return "disapproved";
        case 67:
          return "interview";
        case 49:
          return "reserve";
        case 51:
          return "interview-requested";
        case 50:
          return "interview-approved";
        case 56:
          return "rescheduled";
        case 60:
          return "success";
        case 86:
          return "low-priority";
        case 85:
          return "medium-priority";
        case 84:
          return "high-priority";
        default:
          return "info";
      }
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    speciality(data) {
      let highestQualification = null;
      data.qualifications &&
        data.qualifications.length &&
        data.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
          }
        });
      let speciality = data.speciality?.speciality || null;
      let sub_speciality = data.sub_speciality?.sub_speciality || null;
      highestQualification = highestQualification
        ? highestQualification.qualification.qualification_with_acronym
        : null;
      if (highestQualification) {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality + "|" + highestQualification;
        } else if (speciality) {
          return speciality + "|" + highestQualification;
        } else {
          return highestQualification;
        }
      } else {
        if (sub_speciality) {
          return speciality + "|" + sub_speciality;
        } else if (speciality) {
          return speciality;
        } else {
          return null;
        }
      }
    },
    // getCountry(data) {
    //   return data.location?.country?.country_name || null;
    // },
    getProfilePic(data) {
      return data.profile_picture;
    },
    navigateToCandidateDetail(data) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.resetCandidateDetail();
      this.$router.push({ path: `/candidate/${data.candidate_uid}` });
    },
    navigateToJobListPreview(item) {
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push({ path: `/job-list-preview/${item?.job_id}` });
    },
    imageLoadOnError(e) {
      e.target.src = "/img/user_default_pic.png";
    },
    addToScratch() {
      const { candidate, job_display_uid, job_id, job_title } = this.item;
      const name = `<a href='/candidate/${
        candidate.candidate_uid
      }'>${this.toTitleCase(candidate.first_name)} ${this.toTitleCase(
        candidate.surname
      )} (ID-${
        candidate.display_uid
      })</a> for Job <a href="/job-list-preview/${job_id}">${job_title} | ${job_display_uid}</a>`;
      this.scratchpad.todo = {
        title: `Candidate - ${this.toTitleCase(
          candidate.first_name
        )} ${this.toTitleCase(candidate.surname)} (ID-${
          candidate.display_uid
        }) for Job ${job_title} | ${job_display_uid}`,
        description: `<p>Please review candidate ${name}</p>`,
      };
      this.scratchpad.isShowPopup = true;
    },
    scratchPadModalCallBack(action, payload) {
      if (action && payload) {
        this.postScratchPad(payload);
      }
      this.scratchpad.isShowPopup = false;
      this.scratchpad.todo = null;
    },
    previewCV(candidate) {
      const { link_to_cv_doc, cv_ext: document_ext } = candidate;
      let link_to_doc = `https://${window.location.host}${link_to_cv_doc}`;
      link_to_doc = appendAccessToken(link_to_doc);
      this.preview({ link_to_doc, document_ext });
    },
    preview(data) {
      const { link_to_doc: link_to_document, document_ext } = data;
      this.iframe.url = link_to_document;
      const _baseUrl = BASE_URL;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(document_ext)) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src = `https://view.officeapps.live.com/op/embed.aspx?src=${link_to_document}`;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    openShortListModal(item) {
      this.shortListModal.isShowPopup = true;
      this.shortListModal.candidateDetail = item?.candidate;
    },
    shortListModalCallBack(action, data) {
      this.shortListModal.isShowPopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-job-title {
  text-align: center;
  .image-wrapper img {
    width: 80px;
  }
}
.cursor-action {
  cursor: pointer;
}
.candidate-status {
  position: absolute;
  bottom: 1.24em;
  left: 1.4em;
  z-index: 1;
}
.link {
  color: blue;
  font-size: 13px;
  font-weight: 400;
}

a.link:hover {
  color: #dc0050;
  text-decoration: underline;
}
.fs-14 {
  font-size: 14px !important;
}
</style>
